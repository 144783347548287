<template>
	<HeaderOrder v-if="$route.path.includes('order')"/>
  <HeaderDashboard :user-profile="userProfile" v-if="$route.path.includes('dashboard')"/>
	<router-view :user="user"/>
  <Notification v-if="notify"/>
</template>

<script>
import HeaderOrder from '@/components/HeaderOrder';
import HeaderDashboard from '@/components/HeaderDashboard';
import Notification from '@/components/Notification';

export default {
	components: {
    Notification,
    HeaderOrder,
    HeaderDashboard
	},
  data () {
    return {
      notify: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    userProfile () {
      return this.$store.state.userProfile;
    }
  },
  mounted () {

    setInterval(() => {

      if (document.cookie.indexOf('mylox-token=') === -1) {
        this.$store.commit('setLoggedIn', false);

        if (!this.$route.path.includes('/password-recover')
          && !this.$route.path.includes('/order')
          && !this.$route.path.includes('/login')
        ) {
          this.$router.push({ path: '/login', query: { redirect: this.$route.path }});
        }
      }
    }, 2000);
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/main.scss';
</style>
