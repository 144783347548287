<template>
	<header id="alternate-header" v-if="userProfile">
    <img src="@/assets/img/logo-dt-header.svg" alt="Logo Direct Transport" @click="goToSite"/>
		<button class="navbar-toggler" :class="{ 'collapsed': !showMenu }" type="button" @click="showMenu = !showMenu">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div id="site-navbar" class="navbar-collapse" :class="{ 'collapse': !showMenu }" v-if="showMenu">
			<ul id="menu-main-menu" class="navbar-nav mr-auto">
        <li><router-link :to="'/dashboard/' + userProfile + '-index'"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.00097 7.66489L9.00178 1L17 7.664V17H1.00093H1L1.00097 7.66489Z" stroke="#282A2F" stroke-width="1.25" stroke-linecap="round"/></svg>Dashboard</router-link></li>
        <li><router-link :to="'/dashboard/' + userProfile + '-settings'"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.8156 15.4316L11.5156 15.4316L11.5156 16.6816L17.8156 16.6816L17.8156 15.4316Z" fill="#282A2F"/><path d="M8.4875 15.4316L2.1875 15.4316L2.1875 16.6816L8.4875 16.6816L8.4875 15.4316Z" fill="#282A2F"/><path d="M17.8167 9.37695L10 9.37695L10 10.627L17.8167 10.627L17.8167 9.37695Z" fill="#282A2F"/><path d="M6.97083 9.37695L2.1875 9.37695L2.1875 10.627L6.97083 10.627L6.97083 9.37695Z" fill="#282A2F"/><path d="M17.8146 3.32227L13.0312 3.32227L13.0313 4.57227L17.8146 4.57227L17.8146 3.32227Z" fill="#282A2F"/><path d="M10.0003 3.32227L2.18359 3.32227L2.18359 4.57227L10.0003 4.57227L10.0003 3.32227Z" fill="#282A2F"/><path d="M12.1406 18.3301L12.1406 13.7884L10.8906 13.7884L10.8906 18.3301L12.1406 18.3301Z" fill="#282A2F"/><path d="M7.59375 12.2793L7.59375 7.7293L6.34375 7.7293L6.34375 12.2793L7.59375 12.2793Z" fill="#282A2F"/><path d="M13.6562 6.21289L13.6562 1.67122L12.4062 1.67122L12.4062 6.21289L13.6562 6.21289Z" fill="#282A2F"/></svg>Instellingen</router-link></li>
        <li><a href="#" @click="doLogout"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.9987 10.0007L17.4406 10.4426L17.8826 10.0007L17.4406 9.55871L16.9987 10.0007ZM16.9987 9.37565H7.5V10.6257H16.9987V9.37565ZM17.4406 9.55871L13.4419 5.56001L12.5581 6.44389L16.5568 10.4426L17.4406 9.55871ZM16.5568 9.55871L12.5581 13.5574L13.4419 14.4413L17.4406 10.4426L16.5568 9.55871Z" fill="#282A2F"/><path d="M3.00079 17L2.37579 17L2.37583 17.625H3.00079V17ZM3 3.00113L2.9999 2.37613L2.37496 2.37623L2.375 3.00116L3 3.00113ZM10 16.375H3.00079V17.625H10V16.375ZM3.62579 17L3.625 3.00109L2.375 3.00116L2.37579 17L3.62579 17ZM3.0001 3.62613L10.0001 3.625L9.9999 2.375L2.9999 2.37613L3.0001 3.62613Z" fill="#282A2F"/></svg>Uitloggen</a></li>
			</ul>
		</div>
	</header>
</template>

<script>

export default {
  props: ['userProfile'],
  data () {
    return {
      showMenu: false
    }
  },
  methods: {
    goToSite () {
      window.location = 'https://www.directtransport.nl';
    },
    doLogout () {

      fetch('/api/logout', {
        method: 'PATCH'
      })
        .then(() => {
          this.$store.commit('setLoggedIn', false);
          this.$router.push({ path: '/login', query: { redirect: this.$route.path }});
        })
    }
  },
  watch : {
    $route () {
      this.showMenu = false;
    }
  }
}

</script>
