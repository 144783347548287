import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    loggedIn: false,
    user: {
      'id': '',
      'name': '',
      'customerId': null,
      'courierId': null
    },
    userProfile: ''
  },
  mutations: {
    setLoggedIn(state, payload) {
      state.loggedIn = payload;

      if (payload === false) {
        state.user = {
          'id': '',
          'name': '',
          'customerId': null,
          'courierId': null
        }
        state.userProfile = '';
      }
    },
    setUser(state, payload) {
      Object.assign(state.user, payload);

      if (payload.profile
        && ['admin', 'courier', 'customer'].includes(payload.profile)
      ) {
        state.userProfile = payload.profile;
      }
    }
  },
  getters: {
    userProfile(state) {
      return state.userProfile;
    }
  },
  actions: {},
  modules: {},
  plugins: [ createPersistedState() ]
})
