<template>
  <div class="notification" :class="this.isError ? 'notification-error' : 'notification-success'">
    <div class="text">
			<p class="strong">{{ this.title }}</p>
			<p>{{ this.text }}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    isError: {
      type: Boolean,
      default: false
    }
  }
}

</script>
