import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const
  routes = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: '/password-recover/:code?',
    name: 'Wachtwoord herstellen',
    props: true,
    component: () => import('../views/PasswordRecover')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error')
  },
  {
    path: '/order/',
    redirect: '/order/soort-transport'
  },
  {
    path: '/order/:step',
    name: 'Order',
    component: () =>
        import(/* webpackChunkName: 'order' */ '../views/Order'),
  },
  {
    path: '/dashboard/admin-index',
    name: 'Dashboard admin - Index',
    component: () =>
        import(/* webpackChunkName: 'dashboard-admin' */ '../views/dashboard/AdminIndex')
  },
  {
    path: '/dashboard/admin-couriers',
    name: 'Dashboard admin - Koeriers',
    component: () =>
        import(/* webpackChunkName: 'dashboard-admin' */ '../views/dashboard/AdminCouriers')
  },
  {
    path: '/dashboard/admin-settings',
    name: 'Dashboard admin - Instellingen',
    component: () =>
        import(/* webpackChunkName: 'dashboard-admin' */ '../views/dashboard/AdminSettings')
  },
  {
    path: '/dashboard/admin-transports',
    name: 'Dashboard admin - Transporten',
    component: () =>
        import(/* webpackChunkName: 'dashboard-admin' */ '../views/dashboard/AdminTransports')
  },
  {
    path: '/dashboard/admin-users',
    name: 'Dashboard admin - Gebruikers',
    component: () =>
        import(/* webpackChunkName: 'dashboard-admin' */ '../views/dashboard/AdminUsers')
  },
  {
    path: '/dashboard/courier-index',
    name: 'Dashboard koerier - Index',
    component: () =>
        import(/* webpackChunkName: 'dashboard-courier' */ '../views/dashboard/CourierIndex')
  },
  {
    path: '/dashboard/courier-settings',
    name: 'Dashboard koerier - Instellingen',
    component: () =>
        import(/* webpackChunkName: 'dashboard-courier' */ '../views/dashboard/CourierSettings')
  },
  {
    path: '/dashboard/courier-transports-open',
    name: 'Dashboard koerier - Openstaande Transporten',
    component: () =>
        import(/* webpackChunkName: 'dashboard-courier' */ '../views/dashboard/CourierTransportsOpen')
  },
  {
    path: '/dashboard/courier-transports-accepted/:id?',
    name: 'Dashboard koerier - Aangenomen Transporten',
    props: true,
    component: () =>
        import(/* webpackChunkName: 'dashboard-courier' */ '../views/dashboard/CourierTransportsAccepted')
  },
  {
    path: '/dashboard/courier-transports-delivered',
    name: 'Dashboard koerier - Afgeleverde Transporten',
    component: () =>
        import(/* webpackChunkName: 'dashboard-courier' */ '../views/dashboard/CourierTransportsDelivered')
  },
  {
    path: '/dashboard/customer-index',
    name: 'Dashboard - Index',
    component: () =>
        import(/* webpackChunkName: 'dashboard-customer' */ '../views/dashboard/CustomerIndex')
  },
  {
    path: '/dashboard/customer-settings',
    name: 'Dashboard - Instellingen',
    component: () =>
        import(/* webpackChunkName: 'dashboard-customer' */ '../views/dashboard/CustomerSettings')
  },
  {
    path: '/dashboard/customer-transports',
    name: 'Dashboard - Transporten',
    component: () =>
        import(/* webpackChunkName: 'dashboard-customer' */ '../views/dashboard/CustomerTransports')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error#404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const userProfile = store.getters['userProfile'];

  if (to.matched.length
    && to.matched[0].path.split('/')[1] === 'dashboard'
    && !to.matched[0].path.split('/')[2].includes(userProfile)
  ) {
    next('/error#401');
  }

  next();
});

export default router
